import React from 'react';
import { FCAchievementBaseIcon } from './typedefs';
import { BaseAchievementIcon } from '.';

export const AchievementIconLockedShield: FCAchievementBaseIcon = ({
  withShadow,
  shadowProps,
  iconHeight,
  iconWidth,
}) => (
  <BaseAchievementIcon
    withShadow={withShadow}
    shadowProps={shadowProps}
    iconHeight={iconHeight}
    iconWidth={iconWidth}
  >
    <svg
      width={iconWidth || 88}
      height={iconHeight || 88}
      viewBox="-5 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M39 8.75L0 0V49C0 70.5391 17.4609 88 39 88C60.5391 88 78 70.5391 78 49V0L39 8.75Z" fill="currentColor" />
    </svg>
  </BaseAchievementIcon>
);
