import React, { FC } from 'react';
import capitalize from 'lodash/capitalize';
import { PlatformTag, TagSize } from '@/components/ui/platform/PlatformTag';
import { TagColorMode } from '@/components/ui/platform/PlatformTag/typedefs';
import { AchievementTagType } from '@/components/platform/Achievements/typedefs';
import { AchievementTagHarnessSelectors } from '@/components/platform/Achievements/components/AchievementTag.harness';
import styles from './AchievementTag.module.scss';

interface Props {
  tagType: AchievementTagType;
}

export const AchievementTag: FC<Props> = ({ tagType }) => {
  const colorMode = tagType === AchievementTagType.New
    ? TagColorMode.Brand
    : TagColorMode.Yellow;

  const tagText = capitalize(tagType);

  const dataQa = tagType === AchievementTagType.New
    ? AchievementTagHarnessSelectors.New
    : AchievementTagHarnessSelectors.Rare;

  return (
    <PlatformTag
      dataQa={dataQa}
      text={tagText}
      size={TagSize.Small}
      colorMode={colorMode}
      className={styles.badge}
    />
  );
};
