import { AchievementItemModalHarness } from '@/components/platform/Achievements/components/AchievementItemModal/AchievementItemModal.harness';
import { AchievementsCategoryHarness } from '@/components/platform/Achievements/components/AchievementsCategory/AchievementsCategory.harness';
import { ComponentHarness } from '@/testing/cypress/support/ComponentHarness';

export enum AchievementsContainerHarnessSelectors {
  DataQa = 'achievement-container',
}

export class AchievementsContainerHarness extends ComponentHarness {
  dataQa = AchievementsContainerHarnessSelectors.DataQa;

  get modal() {
    return new AchievementItemModalHarness();
  }

  get category() {
    return new AchievementsCategoryHarness();
  }
}
