import React from 'react';
import { FCAchievementBaseIcon } from './typedefs';
import { BaseAchievementIcon } from '.';

export const AchievementIconLockedHexagon: FCAchievementBaseIcon = ({
  withShadow,
  shadowProps,
  iconHeight,
  iconWidth,
}) => (
  <BaseAchievementIcon
    withShadow={withShadow}
    shadowProps={shadowProps}
    iconHeight={iconHeight}
    iconWidth={iconWidth}
  >
    <svg
      width={iconWidth || 88}
      height={iconHeight || 88}
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M46 1.21525C44.7624 0.500717 43.2376 0.500716 42 1.21525L7.89488 20.9058C6.65728 21.6204 5.89488 22.9409 5.89488 24.3699V63.7511C5.89488 65.1802 6.65728 66.5007 7.89488 67.2152L42 86.9058C43.2376 87.6204 44.7624 87.6204 46 86.9058L80.1051 67.2152C81.3427 66.5007 82.1051 65.1802 82.1051 63.7511V24.3699C82.1051 22.9409 81.3427 21.6204 80.1051 20.9058L46 1.21525Z" fill="currentColor" />
    </svg>
  </BaseAchievementIcon>
);
