import { AchievementsContainerHarness } from '@/components/platform/Achievements/components/AchievementsContainer/AchievementsContainer.harness';
import { SubheaderHarness } from '@/components/platform/common/Subheader/Subheader.harness';
import { ComponentHarness } from '@/testing/cypress/support/ComponentHarness';

export enum AchievementsPageHarnessSelectors {
  DataQa = 'achievement-page',
}

export class AchievementsPageHarness extends ComponentHarness {
  dataQa = AchievementsPageHarnessSelectors.DataQa;

  get subheader() {
    return new SubheaderHarness();
  }

  get container() {
    return new AchievementsContainerHarness();
  }

  assertRenderedCorrectly() {
    this.subheader.assertExists();
    this.subheader.assertTitleHasText('achievements_header_title');
    this.subheader.backButton.should('be.visible');
    this.container.assertExists();
    this.container.category.assertExists();
  }
}
