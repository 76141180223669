import { ComponentHarness } from '@/testing/cypress/support/ComponentHarness';

export enum AchievementTagHarnessSelectors {
  New = 'achievement-new-badge',
  Rare = 'achievement-rare-tag',
}

export class AchievementTagHarness extends ComponentHarness {
  dataQa = AchievementTagHarnessSelectors.New;

  get rareTag() {
    return this.getElementByDataQa(AchievementTagHarnessSelectors.Rare);
  }

  assertRareTagExists() {
    return this.rareTag.should('exist');
  }

  assertRareTagDoesNotExist() {
    return this.rareTag.should('not.exist');
  }
}
