import { ComponentHarness } from '@/testing/cypress/support/ComponentHarness';

export enum AchievementIconHarnessSelectors {
  DataQa = 'achievement-icon',
  Unknown = 'achievement-icon_unknown',
  Custom = 'achievement-icon_custom',
}

export class AchievementIconHarness extends ComponentHarness {
  dataQa = AchievementIconHarnessSelectors.DataQa;

  get unlocked() {
    return this.getElement();
  }

  get unknown() {
    return this.getElementByDataQa(AchievementIconHarnessSelectors.Unknown);
  }

  get custom() {
    return this.getElementByDataQa(AchievementIconHarnessSelectors.Custom);
  }
}
