import { AchievementIconHarness } from '@/components/platform/Achievements/components/AchievementIcon.harness';
import { AchievementTagHarness } from '@/components/platform/Achievements/components/AchievementTag.harness';
import { ComponentHarness } from '@/testing/cypress/support/ComponentHarness';

export enum AchievementItemHarnessSelectors {
  DataQa = 'achievement-item',
  Name = 'achievement-item_name',
  Category = 'achievement-item_category',
}

export class AchievementItemHarness extends ComponentHarness {
  dataQa = AchievementItemHarnessSelectors.DataQa;

  get name() {
    return this.getNestedElement(AchievementItemHarnessSelectors.Name);
  }

  get category() {
    return this.getNestedElement(AchievementItemHarnessSelectors.Category);
  }

  get icon() {
    return new AchievementIconHarness();
  }

  get achievementTag() {
    return new AchievementTagHarness();
  }

  // get rareBadge() {
  //   return new AchievementTagHarness().rareTag;
  // }

  clickOnFirstAchievement() {
    this.getElement().first().click();
  }

  assertUnlocked() {
    this.getElement().should('have.attr', 'data-qa-unlocked', 'true');
  }

  assertLocked() {
    this.getElement().should('not.have.attr', 'data-qa-unlocked');
  }

  assertRenderedCorrectly(options: {
    name: string;
    category: string;
    isUnlocked: boolean;
    isNew: boolean;
    isRare: boolean;
    withIcon: boolean;
  }) {
    const {
      name,
      category,
      isUnlocked,
      isNew,
      isRare,
      withIcon,
    } = options;

    this.name.each(
      (element) => cy.wrap(element).should('have.text', `name.${name}`),
    );
    this.category.each(
      (element) => cy.wrap(element).should('have.text', `category.${category}`),
    );

    if (isUnlocked) {
      this.assertUnlocked();
    } else {
      this.assertLocked();
    }

    if (isNew) {
      this.achievementTag.assertExists();
    } else {
      this.achievementTag.assertNotExist();
    }

    if (isRare) {
      this.achievementTag.assertRareTagExists();
    } else {
      this.achievementTag.assertRareTagDoesNotExist();
    }

    if (withIcon) {
      this.icon.assertExists();
    } else {
      this.icon.assertNotExist();
    }
  }
}
