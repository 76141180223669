import { ComponentHarness } from '@/testing/cypress/support/ComponentHarness';

export enum SubheaderHarnessSelectors {
  DataQa = 'subheader',
  BackButton = 'subheader_back-button',
  Title = 'subheader_title',
}

export class SubheaderHarness extends ComponentHarness {
  dataQa = SubheaderHarnessSelectors.DataQa;

  get backButton() {
    return this.getElementByDataQa(SubheaderHarnessSelectors.BackButton);
  }

  get title() {
    return this.getElementByDataQa(SubheaderHarnessSelectors.Title);
  }

  assertTitleHasText(text: string) {
    this.title.should('have.text', text);
  }
}
