import React, {
  FC,
  useCallback,
} from 'react';
import i18Next, { Link } from '@/middleware/i18n';
import { IconArrowLeft } from '@/components/ui/icons/IconArrowLeft';
import { Button, ButtonMode, ButtonSize } from '@/components/ui/Button';

import { FCIcon } from '@/components/ui/icons/typedefs';

interface Props {
  route?: string;
  text?: string;
  onClick?: () => void;
  leftIcon?: FCIcon;
  shouldUseOnClickToNavigate?: boolean;
  className?: string;
  dataQa?: string;
}

export const BackButton: FC<Props> = (props) => {
  const {
    route,
    text = '',
    onClick,
    leftIcon = IconArrowLeft,
    shouldUseOnClickToNavigate = false,
    className,
    dataQa,
  } = props;
  const { back } = i18Next.useRouter();

  const handleClick = useCallback(() => {
    if (shouldUseOnClickToNavigate) {
      onClick?.();

      return;
    }

    back();
  }, [
    back,
    onClick,
    shouldUseOnClickToNavigate,
  ]);

  return (
    route
      ? (
        <Link href={route} passHref>
          <Button
            text={text}
            LeftIcon={leftIcon}
            mode={ButtonMode.TransparentLight}
            size={ButtonSize.Small}
            href={route}
            onClick={onClick}
            className={className}
            data-qa={dataQa}
          />
        </Link>
      )
      : (
        <Button
          data-qa={dataQa}
          text={text}
          LeftIcon={leftIcon}
          mode={ButtonMode.TransparentLight}
          size={ButtonSize.Small}
          onClick={handleClick}
          className={className}
        />
      )
  );
};
