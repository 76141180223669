import React from 'react';
import { FCAchievementBaseIcon } from './typedefs';
import { BaseAchievementIcon } from '.';

export const AchievementIconLockedShieldRotated: FCAchievementBaseIcon = ({
  withShadow,
  shadowProps,
  iconHeight,
  iconWidth,
}) => (
  <BaseAchievementIcon
    withShadow={withShadow}
    shadowProps={shadowProps}
    iconHeight={iconHeight}
    iconWidth={iconWidth}
  >
    <svg
      width={iconWidth || 88}
      height={iconHeight || 88}
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M44.5 88L83 70.5056V39C83 17.4609 65.5391 0 44 0C22.4609 0 5 17.4609 5 39V70.5056L44.5 88Z" fill="currentColor" />
    </svg>
  </BaseAchievementIcon>
);
