import React, { useState } from 'react';

export const useStateRef = <T>(): [
  T | null,
  React.Ref<T>
] => {
  const [element, setElement] = useState<T | null>(null);

  return [element, (value) => setElement(value)];
};
