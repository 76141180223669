import React from 'react';
import { FCAchievementBaseIcon } from './typedefs';
import { BaseAchievementIcon } from '.';

export const AchievementIconLockedStar: FCAchievementBaseIcon = ({
  withShadow,
  shadowProps,
  iconHeight,
  iconWidth,
}) => (
  <BaseAchievementIcon
    withShadow={withShadow}
    shadowProps={shadowProps}
    iconHeight={iconHeight}
    iconWidth={iconWidth}
  >
    <svg
      width={iconWidth || 88}
      height={iconHeight || 88}
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M41.1724 2.82591C42.7343 1.26493 45.2657 1.26493 46.8276 2.82591L55.7186 11.7116C56.4684 12.461 57.485 12.8821 58.545 12.8824L71.1151 12.8861C73.3233 12.8868 75.1132 14.6767 75.1139 16.8849L75.1176 29.455C75.1179 30.515 75.539 31.5316 76.2884 32.2814L85.1741 41.1724C86.7351 42.7343 86.7351 45.2657 85.1741 46.8276L76.2884 55.7186C75.539 56.4684 75.1179 57.485 75.1176 58.545L75.1139 71.1151C75.1132 73.3233 73.3233 75.1132 71.1151 75.1139L58.545 75.1176C57.485 75.1179 56.4684 75.539 55.7186 76.2884L46.8276 85.1741C45.2657 86.7351 42.7343 86.7351 41.1724 85.1741L32.2814 76.2884C31.5316 75.539 30.515 75.1179 29.455 75.1176L16.8849 75.1139C14.6767 75.1132 12.8868 73.3233 12.8861 71.1151L12.8824 58.545C12.8821 57.485 12.461 56.4684 11.7116 55.7186L2.82591 46.8276C1.26493 45.2657 1.26493 42.7343 2.82591 41.1724L11.7116 32.2814C12.461 31.5316 12.8821 30.515 12.8824 29.455L12.8861 16.8849C12.8868 14.6767 14.6767 12.8868 16.8849 12.8861L29.455 12.8824C30.515 12.8821 31.5316 12.461 32.2814 11.7116L41.1724 2.82591Z" fill="currentColor" />
    </svg>
  </BaseAchievementIcon>
);
