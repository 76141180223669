import React, {
  DetailedHTMLProps, FC, HTMLAttributes, useMemo,
} from 'react';
import { cn } from '@/lib/classNames';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { TagColorMode } from '@/components/ui/platform/PlatformTag/typedefs';
import { FCImage } from '@/components/ui/IconEmojies/typedefs';
import styles from './PlatformTag.module.scss';

export enum TagSize {
  Small = 'small',
  Large = 'large',
}

const containerSizeClassNames: Record<TagSize, string> = {
  [TagSize.Small]: styles.small,
  [TagSize.Large]: styles.large,
};

const iconSizeClassNames: Record<TagSize, string> = {
  [TagSize.Small]: styles.iconSmall,
  [TagSize.Large]: styles.iconLarge,
};

const tagColorModeClassNames: Record<TagColorMode, string> = {
  [TagColorMode.Gray]: styles.gray,
  [TagColorMode.Brand]: styles.brand,
  [TagColorMode.Blue]: styles.blue,
  [TagColorMode.Green]: styles.green,
  [TagColorMode.Yellow]: styles.yellow,
  [TagColorMode.Purple]: styles.purple,
  [TagColorMode.Pink]: styles.pink,
  [TagColorMode.Danger]: styles.danger,
  [TagColorMode.None]: styles.none,
};

type PlatformTagElementProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

interface CustomProps {
  IconElement?: FCIcon | FCImage;
  text: string;
  size?: TagSize;
  colorMode?: TagColorMode;
  iconColor?: string;
  dataQa?: string;
}

type PlatformTagProps = PlatformTagElementProps & CustomProps;

type PlatformTagType = FC<PlatformTagProps> & {
  colorMode: typeof TagColorMode;
};

export const PlatformTag: PlatformTagType = (props) => {
  const {
    IconElement,
    text,
    size = TagSize.Large,
    colorMode = TagColorMode.Gray,
    iconColor,
    className,
    dataQa = 'platform_tag',
  } = props;

  const rootClassName = useMemo(
    () => (
      cn(
        containerSizeClassNames[size],
        tagColorModeClassNames[colorMode],
      )),
    [colorMode, size],
  );

  return (
    <div
      className={cn(
        styles.container,
        rootClassName,
        { [styles.withoutIcon]: !IconElement },
        className,
      )}
      data-qa={dataQa}
    >
      {IconElement && (
        <IconElement
          color={iconColor}
          className={cn(styles.icon, iconSizeClassNames[size])}
        />
      )}

      <p>{text}</p>
    </div>
  );
};

PlatformTag.colorMode = TagColorMode;
