import { AchievementItemHarness } from '@/components/platform/Achievements/AchievementItem.harness';
import { AchievementsCategoryBlockedIconHarness } from '@/components/platform/Achievements/components/AchievementsCategoryBlockedIcon/AchievementsCategoryBlockedIcon.harness';
import { ComponentHarness } from '@/testing/cypress/support/ComponentHarness';

export enum AchievementsCategoryHarnessSelectors {
  DataQa = 'achievement-category',
  Navigation = 'achievement-category_navigation',
  Title = 'achievement-category_title',
}

export class AchievementsCategoryHarness extends ComponentHarness {
  dataQa = AchievementsCategoryHarnessSelectors.DataQa;

  get navigation() {
    return this.getElementByDataQa(
      AchievementsCategoryHarnessSelectors.Navigation,
    );
  }

  get title() {
    return this.getElementByDataQa(
      AchievementsCategoryHarnessSelectors.Title,
    );
  }

  get achievementItem() {
    return new AchievementItemHarness();
  }

  get blockedIcon() {
    return new AchievementsCategoryBlockedIconHarness();
  }

  assertRenderedCorrectly(options: {
    count: number;
    category: string;
    isCategoryBlocked: boolean;
    withNavigation: boolean;
  }) {
    const {
      count,
      category,
      isCategoryBlocked,
      withNavigation,
    } = options;

    this.title.should('have.text', `category.${category}`);

    if (isCategoryBlocked) {
      this.blockedIcon.assertExists();
    } else {
      this.blockedIcon.assertNotExist();
    }

    if (withNavigation) {
      this.navigation.should('be.visible');
    } else {
      this.navigation.should('not.be.visible');
    }

    this.achievementItem.assertExists();
    this.achievementItem.getElement().should('have.length', count);
  }
}
