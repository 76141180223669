import React, { FC } from 'react';
import { BackButton } from '@/components/ui/BackButton';
import { SubheaderHarnessSelectors } from '@/components/platform/common/Subheader/Subheader.harness';
import styles from './Subheader.module.scss';

type Props = {
  title: string;
  withBackButton?: boolean;
};

export const Subheader: FC<Props> = ({
  title,
  withBackButton = false,
}) => (
  <div
    data-qa={SubheaderHarnessSelectors.DataQa}
    className={styles.container}
  >
    <div className={styles.titleContainer}>
      {withBackButton && (
        <div className={styles.backButton}>
          <BackButton
            dataQa={SubheaderHarnessSelectors.BackButton}
          />
        </div>
      )}

      <div
        data-qa={SubheaderHarnessSelectors.Title}
        className={styles.title}
      >
        {title}
      </div>
    </div>
  </div>
);
