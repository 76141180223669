import React from 'react';
import { FCAchievementBaseIcon } from './typedefs';
import { BaseAchievementIcon } from '.';

export const AchievementIconLockedCircle: FCAchievementBaseIcon = ({
  withShadow,
  shadowProps,
  iconHeight,
  iconWidth,
}) => (
  <BaseAchievementIcon
    withShadow={withShadow}
    shadowProps={shadowProps}
    iconHeight={iconHeight}
    iconWidth={iconWidth}
  >
    <svg
      width={iconWidth || 88}
      height={iconHeight || 88}
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="44" cy="44" r="44" transform="matrix(1 0 0 -1 0 88)" fill="currentColor" />
    </svg>
  </BaseAchievementIcon>
);
