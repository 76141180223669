import React, { FC } from 'react';
import { IconLock } from '@/components/ui/icons/IconLock';
import {
  HorizontalPositionMode,
  ToolTip,
  ToolTipWidthMode,
  VerticalPositionMode,
} from '@/components/ui/ToolTip';
import { AchievementsCategoryBlockedIconHarnessSelectors } from '@/components/platform/Achievements/components/AchievementsCategoryBlockedIcon/AchievementsCategoryBlockedIcon.harness';
import styles from './AchievementsCategoryBlockedIcon.module.scss';

type Props = {
  blockedReasonTranslation: string;
};

export const AchievementsCategoryBlockedIcon: FC<Props> = ({
  blockedReasonTranslation,
}) => (
  <div
    data-qa={AchievementsCategoryBlockedIconHarnessSelectors.DataQa}
    className={styles.blockedIconWrapper}
  >
    <ToolTip
      className={styles.blockedIconToolTip}
      text={blockedReasonTranslation}
      widthMode={ToolTipWidthMode.Max}
      verticalPosition={VerticalPositionMode.Bottom}
      horizontalPosition={HorizontalPositionMode.LeftSide}
      isManualVerticalPositioning
    >
      <IconLock className={styles.blockedIcon} />
    </ToolTip>
  </div>
);
