import { FC } from 'react';
import { NextPage } from 'next';
import { LayoutOptions } from '@/typedefs/pageProps';
import { LayoutProps } from '@/components/layout/LayoutProps';
import { PageProps } from '@/controllers/page/page.typedefs';

export type WithLayoutResult = (
  PageComponent: NextPage<PageProps>
) => NextPage<PageProps>;

export type WithLayout = (
  Layout: FC<LayoutProps>,
  layoutOptions?: LayoutOptions
) => WithLayoutResult;

export const withLayout: WithLayout = (
  Layout,
  layoutOptions,
) => (PageComponent) => {
  Object.assign(
    PageComponent,
    { Layout, layoutOptions },
  );

  return PageComponent;
};
