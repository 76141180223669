export enum TagColorMode {
  Gray = 'gray',
  Brand = 'brand',
  Blue = 'blue',
  Green = 'green',
  Yellow = 'yellow',
  Purple = 'purple',
  Pink = 'pink',
  Danger = 'danger',
  None = 'none',
}
