import React, {
  FC,
  memo,
  useState,
} from 'react';
import { cn } from '@/lib';
import { ACHIEVEMENT_ICON_HEIGHT, ACHIEVEMENT_ICON_WIDTH, BaseAchievementIcon } from '@/components/ui/icons/achievements';
import { AchievementBaseIconProps, FCAchievementBaseIcon } from '@/components/ui/icons/achievements/typedefs';
import { Image } from '@/components/ui/Image';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { AchievementIconLocked, LockedIconName } from '@/components/platform/Achievements';
import { Loader } from '@/components/ui/Loader';
import { rawImageLoader } from '@/components/ui/Image/utils/imageLoader';
import { AchievementIconHarnessSelectors } from '@/components/platform/Achievements/components/AchievementIcon.harness';
import { useThemedLockedIcon } from '@/components/platform/Achievements/hooks/useThemedLockedIcon';
import styles from './AchievementIcon.module.scss';

export interface AchievementIconOptions {
  name?: string;
  unlocked?: boolean;
  iconLockedUrl?: string;
  iconLockedDarkUrl?: string;
  iconUnlockedUrl?: string;
  customIcon?: FCAchievementBaseIcon;
}

export type AchievementIconProps = AchievementBaseIconProps
  & AchievementIconOptions;

export const AchievementIcon: FC<AchievementIconProps> = memo(({
  name,
  unlocked,
  iconLockedUrl,
  iconLockedDarkUrl,
  iconUnlockedUrl,
  customIcon: CustomIcon,
  ...iconProps
}) => {
  const { t } = useTranslation([I18N_CODES.achievements]);
  const [loading, setLoading] = useState(true);
  const iconThemedLockedUrl = useThemedLockedIcon({
    iconLockedUrl,
    iconLockedDarkUrl,
  });

  if (CustomIcon) {
    return (
      <span
        data-qa={AchievementIconHarnessSelectors.Custom}
        className={styles.icon}
      >
        <CustomIcon {...iconProps} />
      </span>
    );
  }

  const iconUrl = (
    unlocked
      ? iconUnlockedUrl
      : iconThemedLockedUrl
  );

  if (!iconUrl) {
    const AchievementIconUnknown = (
      AchievementIconLocked[LockedIconName.Unknown]
    );

    return (
      <span data-qa={AchievementIconHarnessSelectors.Unknown}>
        <AchievementIconUnknown {...iconProps} />
      </span>
    );
  }

  return (
    <BaseAchievementIcon {...iconProps}>
      <Loader loading={loading} />

      <Image
        data-qa={AchievementIconHarnessSelectors.DataQa}
        className={cn({
          [styles.imageLoaded]: !loading,
        })}
        src={rawImageLoader({ src: iconUrl })}
        alt={(name
          ? t(`${I18N_CODES.achievements}:name.${name}`)
          : ''
        )}
        objectFit="contain"
        onLoadingComplete={() => setLoading(false)}
        width={iconProps.iconWidth || ACHIEVEMENT_ICON_WIDTH}
        height={iconProps.iconHeight || ACHIEVEMENT_ICON_HEIGHT}
        unoptimized
      />
    </BaseAchievementIcon>
  );
});
