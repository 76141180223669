import { AchievementItemHarnessSelectors } from '@/components/platform/Achievements/AchievementItem.harness';
import { ComponentHarness } from '@/testing/cypress/support/ComponentHarness';

export enum AchievementItemModalHarnessSelectors {
  DataQa = 'achievement-item-modal',
  Description = 'achievement-item-modal_description',
  CloseButton = 'close-button',
}

export class AchievementItemModalHarness extends ComponentHarness {
  dataQa = AchievementItemModalHarnessSelectors.DataQa;

  get description() {
    return this.getElementByDataQa(
      AchievementItemModalHarnessSelectors.Description,
    );
  }

  get achievementItem() {
    return this.getNestedElement(
      AchievementItemHarnessSelectors.DataQa,
    );
  }

  get achievementItemName() {
    return this.getNestedElement(
      AchievementItemHarnessSelectors.Name,
    );
  }

  get achievementItemCategory() {
    return this.getNestedElement(
      AchievementItemHarnessSelectors.Category,
    );
  }

  get closeButton() {
    return this.getNestedElement(
      AchievementItemModalHarnessSelectors.CloseButton,
    );
  }

  assertRenderedCorrectly(options: {
    name: string;
    category: string;
  }) {
    const {
      name,
      category,
    } = options;

    this.assertExists();
    this.achievementItemName.should('have.text', `name.${name}`);
    this.achievementItemCategory.should('have.text', `category.${category}`);
    this.description.should('have.text', `${name}_description`);
    this.closeButton.should('be.visible');
  }

  closeModal() {
    this.closeButton.click();
  }
}
