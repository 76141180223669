import React, { FC } from 'react';
import { Modal } from '@/components/ui/Modal';
import { I18N_CODES } from '@/lib/constants/general';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { useTranslation } from '@/middleware/i18n';
import { AchievementItem, ChatGuruChatType } from '@/components/platform/Achievements';
import { AchievementItemModalHarnessSelectors } from '@/components/platform/Achievements/components/AchievementItemModal/AchievementItemModal.harness';
import { useSubscriptionProductFeatures } from '@/components/platform/SubscriptionProduct/hooks/useSubscriptionProductFeatures';
import { AchievementFullFragment } from '@/components/platform/Achievements/graphql/generated/achievementFull.fragment.generated';
import styles from './AchievementItemModal.module.scss';

interface Props {
  achievement?: AchievementFullFragment;
  isModalOpened: boolean;
  onClose: () => void;
}

export const AchievementItemModal: FC<Props> = ({
  achievement,
  isModalOpened,
  onClose,
}) => {
  const { t } = useTranslation([I18N_CODES.achievements]);

  const {
    isAvailable: isSubscriptionProductAvailable,
  } = useSubscriptionProductFeatures();

  const chatType = isSubscriptionProductAvailable
    ? ChatGuruChatType.Community
    : ChatGuruChatType.QnA;

  return (
    <Modal
      dataQa={AchievementItemModalHarnessSelectors.DataQa}
      className={styles.modal}
      autoWidth
      isOpen={isModalOpened}
      onRequestClose={onClose}
      renderTitle={() => (
        <div className={styles.modalTitle}>
          <AchievementItem
            achievement={achievement}
            showToolTip={false}
            showNewBadge={false}
            textTruncated={false}
          />
        </div>
      )}
    >
      <div
        data-qa={AchievementItemModalHarnessSelectors.Description}
        className={cn(styles.modalDescription, typography.platformTextMain)}
      >
        {t(`${I18N_CODES.achievements}:${achievement?.name}_description`, {
          chat_type: chatType,
        })}
      </div>
    </Modal>
  );
};
