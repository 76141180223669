import { NaturalShadowProps } from '@/components/ui/NaturalShadow';

export const ACHIEVEMENT_ICON_WIDTH = 88;
export const ACHIEVEMENT_ICON_HEIGHT = 88;

export const defaultShadowProps: NaturalShadowProps = {
  x: 2,
  y: 5,
  blur: 10,
  opacity: 0.6,
};
