import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconArrowLeft: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.7031 7.70492L11.1231 12.2949L15.7031 16.8849L14.2931 18.2949L8.29312 12.2949L14.2931 6.29492L15.7031 7.70492Z"
      fill="currentColor"
    />
  </BaseIcon>
);
