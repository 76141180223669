import { Features } from '@/controllers/features/features.constants';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';

export interface SubscriptionProductFeaturesOptions {
  additionalFeatureToCheck?: Features;
}

export const useSubscriptionProductFeatures = (
  options?: SubscriptionProductFeaturesOptions,
) => {
  const {
    additionalFeatureToCheck,
  } = options || {};
  const { isSubscriptionProductDomain } = useSubDomainContext();
  const features = useFeatures();
  const isSubscriptionProductEnabled = features.isEnabled(
    Features.SubscriptionProduct,
  );

  const isAvailableBaseFeature = (
    isSubscriptionProductDomain && isSubscriptionProductEnabled
  );

  if (additionalFeatureToCheck) {
    const isEnabled = features.isEnabled(
      additionalFeatureToCheck,
    );

    return ({
      isAvailable: isAvailableBaseFeature && isEnabled,
    });
  }

  return ({
    isAvailable: isAvailableBaseFeature,
  });
};
